<script setup lang="ts">
</script>

<template>
    <div class="search-box">
        <div class="flex">
            <div class="flex1">
                <input type="search" placeholder="投影仪" class="search-input" />
            </div>
            <div style="width: 1rem;"></div>
            <div>
                <button type="button" class="search-button">搜索</button>
            </div>
        </div>
    </div>
</template>

<style>
.search-box{
    margin: 1rem auto;
}
@media only screen and (min-width: 680px) {
    .search-box{
        width:80%;
    }
}
.search-box .search-input{
    width: 100%;
    border-radius: 1.4rem; 
    border: solid 1px silver;
    height: 2.8rem;
    font-size: 1.6em;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: rgb(228, 228, 228);
}
.search-box .search-button{
    height: 2.8rem; line-height: 2.8rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #771EE1;
    border: solid 1px rgba(255, 255, 255, .3);
    border-radius: 0.4rem;
    color: white;
    font-size: 1.2em;
}
</style>