<template>
    <footer class="page-footer flex-center">
        <div class="contact-list">
            <div><label>Email:</label> woojayden@outlook.om</div>
            <div><label>Facebook:</label> woojayden@outlook.om</div>
            <div><label>Twitter:</label> woojayden@outlook.om</div>
        </div>
    </footer>
</template>

<style>
.page-footer {
    border: solid 1px rgba(255, 255, 255, .2);
    border-radius: 1rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding: 2rem;
}

.contact-list>div {
    height: 2rem;
}

.contact-list>div>label {
    font-weight: bolder;
    display: inline-block;
    width: 8rem;
    text-align: right;
}
</style>