<script setup lang="ts">
import IconLanguage from '@/assets/icons/IconLanguage.svg';

const activedPage = ref(0)
</script>

<template>
    <header>
        <div class="header-content flex">
            <div>
                <NuxtLink to="/">
                    <img src="/logo.png" class="logo" />
                </NuxtLink>
            </div>
            <div class="sys-menu flex1 flex-center-hor">
                <div class="flex-space-between sys-menu">
                    <div>
                        <NuxtLink to="/">首页</NuxtLink>
                    </div>
                    <div>
                        <NuxtLink to="/products">产品</NuxtLink>
                    </div>
                    <div>
                        <NuxtLink to="/my-order">我的</NuxtLink>
                    </div>
                </div>
            </div>
            <div>
                <IconLanguage class="icon-language" />
            </div>
        </div>
    </header>
</template>

<style>
header {
    margin-bottom: 2rem;
    border-bottom: solid 1px rgb(113, 113, 113);
    box-shadow: rgba(105, 105, 105, 0.25) 0px 6px 12px -2px, rgba(107, 105, 107, 0.3) 0px 3px 7px -3px;
}

header .header-content {
    align-items: center;
}

header .logo {
    height: 4rem; display: block;
}

header .sys-menu {
    column-gap: 2rem;
}

header a {
    color: white;
    font-size: 1.2em;
}

header .icon-language {
    width: 2rem;
    height: 2rem;
}
</style>